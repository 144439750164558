<template v-if="authUser.roles.some(role => role === 'Super Admin') || authUser.division.length > 1">
  <div v-if="layout === 'inline'">
    <CRow>
      <CFormLabel for="selectDivision" :class="'col-' + colLabelValue + ' col-form-label'">{{ labelText }}</CFormLabel>
      <CCol :xs="colSelectValue">
        <CFormSelect
          id="selectDivision"
          feedbackInvalid="Pilih salah satu divisi"
          required
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option value="">Pilih Divisi</option>
          <option v-for="division in divisions" :key="division.id" :value="division.id">
            {{ division.name }}
          </option>
        </CFormSelect>
      </CCol>
    </CRow>
  </div>
  <CCol v-else-if="layout === 'standard'" :xs="colContainerValue">
    <CFormSelect
      id="selectDivision"
      feedbackInvalid="Pilih salah satu divisi"
      required
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="">Pilih Divisi</option>
      <option v-for="division in divisions" :key="division.id" :value="division.id">
        {{ division.name }}
      </option>
      <template #label>{{ labelText }}</template>
    </CFormSelect>
  </CCol>
  <CCol v-else :xs="colSelectValue">
    <CFormSelect
      id="selectDivision"
      feedbackInvalid="Pilih salah satu divisi"
      required
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="">Pilih Divisi</option>
      <option v-for="division in divisions" :key="division.id" :value="division.id">
        {{ division.name }}
      </option>
    </CFormSelect>
  </CCol>
</template>

<script>
import { mapState } from 'vuex';
import divisionService from '@/services/division-service'

export default {
  props: {
    layout: {
      type: String,
      required: false,
      default: 'other',
    },
    colSelectValue: {
      type: Number,
      required: false,
      default: 6,
    },
    colLabelValue: {
      type: Number,
      required: false,
      default: 6,
    },
    labelText: {
      type: String,
      required: false,
      default: "Divisi",
    },
    colContainerValue: {
      type: Number,
      required: false,
      default: 12,
    },
    modelValue: Number,
  },
  computed: {
    ...mapState(['authUser']),
  },
  data() {
    return {
      divisions: [],
    }
  },
  created() {
    divisionService.getAll().then(resp => {
      // if not a super admin, only display divisions that are assigned to the user
      if (! this.authUser.roles.some(role => role === 'Super Admin')) {
        this.divisions = resp.data.data.filter(item => this.authUser.division.some(div => div.id === item.id));
      }
      // get all divisions if user is super admin
      else {
        this.divisions = resp.data.data
      }
    })
  }
}

</script>