import { defineComponent, h, onMounted, ref, resolveComponent, useAttrs } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import { CBadge, CSidebarNav, CNavItem, CNavGroup, CNavTitle } from '@coreui/vue'
import nav from '@/_nav.js'
import store from '@/store'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const attr = useAttrs()
    const firstRender = ref(true)
    const user = attr.user
    const permissions = attr.permissions
    const checkPermission = (item) => {
      return (
        permissions.includes(item.permission) ||
        item.permission == '' ||
        item.role == '' ||
        user.roles?.includes('Super Admin') ||
        user.roles?.includes(item.role)
      )
    }

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (checkPermission(item)) {
        if (item.items) {
          return h(
            CNavGroup,
            {
              ...(firstRender.value && {
                visible: item.items.some((child) => isActiveItem(route, child)),
              }),
            },
            {
              togglerContent: () => [
                h(resolveComponent('CIcon'), {
                  customClassName: 'nav-icon icon-color-ams',
                  name: item.icon,
                }),
                item.name,
              ],
              default: () => item.items.map((child) => renderItem(child)),
            },
          )
        }

        return item.to
          ? h(
              RouterLink,
              {
                to: item.to,
                custom: true,
              },
              {
                default: (props) =>
                  h(
                    resolveComponent(item.component),
                    {
                      active: props.isActive,
                      href: props.href,
                      onClick: () => props.navigate(),
                    },
                    {
                      default: () => [
                        item.icon &&
                          h(resolveComponent('CIcon'), {
                            customClassName: 'nav-icon icon-color-ams',
                            name: item.icon,
                          }),
                        item.name,
                        item.badge &&
                          h(
                            CBadge,
                            {
                              class: 'ms-auto',
                              color: item.badge.color,
                            },
                            {
                              default: () => item.badge.text,
                            },
                          ),
                      ],
                    },
                  ),
              },
            )
          : h(
              resolveComponent(item.component),
              {},
              {
                default: () => item.name,
              },
            )
      }
    }

    if (!user.id) {
      store.dispatch('getUser').then(() => location.reload())
    }
    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      )
  },
})
export { AppSidebarNav }
