<template>
  <CToaster placement="top-end">
    <template v-for="(toast, index) in toasts" :key="index">
      <CToast :delay="toast.delay" visible>
        <CToastHeader closeButton>
          <svg
            class="rounded me-2"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
            role="img"
          >
            <rect width="100%" height="100%" :fill="colors[toast.color]"></rect>
          </svg>
          <span class="me-auto fw-bold">{{ toast.title }}</span>
          <small>baru saja</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
      </CToast>
    </template>
  </CToaster>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Toast',
  data() {
    return {
      colors: {
        info: '#3399ff',
        success: '#2eb85c',
        warning: '#f9b115',
        danger: '#e55353',
      },
    }
  },
  computed: {
    ...mapState(['toasts']),
  },
}
</script>
