import axios from '@/libs/axios-instance'

class DivisionService {
  formData = {
    name: '',
    code: '',
    logo: '',
    address: '',
    telp: '',
    purchase_request_format: '',
    purchase_order_format: '',
  }
  id = null
  errData = {
    name: [],
    code: [],
  }

  getAll(uri = null) {
    const target = uri || 'division'
    return axios.get(target)
  }
  getDivision(page = 1, q = '', limit = 10) {
    return axios.get(`division?page=${page}&q=${q}&limit=${limit}`)
  }
  getDetail() {
    return axios.get('division/' + this.id)
  }
  saveDivision() {
    let result = {}
    if (this.id) {
      result = axios.put('division/' + this.id, this.formData)
    } else {
      result = axios.post('division', this.formData)
    }
    result.catch(({ response }) => {
      if (response.data.code) {
        this.errData.code = response.data.code
      }
      if (response.data.name) {
        this.errData.name = response.data.name
      }
    })
    return result
  }
  deleteDivision(id) {
    return axios.delete('division/' + id)
  }
}

export default new DivisionService()
