<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" class="me-2" />
      <span class="float-end" style="line-height: 1.15">
        {{ `${user?.first_name} ${user?.last_name}` }}
        <br />
        <small class="text-muted" style="font-size: 0.8em">
          <i>{{ user?.division?.name }}</i>
        </small>
      </span>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2"> Settings </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logout()"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/1.png'
import { mapActions } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  props: {
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    ...mapActions(['prepareLogout']),
    async logout() {
      await this.prepareLogout()
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>
