<template>
  <div @click="showHelpSidebar" class="help-button">
    <img :src="Help" height="50" />
  </div>
  <div class="help-panel" :class="{ open: isHelpSidebarOpen }" ref="helpPanel">
    <div v-if="isHelpSidebarOpen">
      <div v-html="helpTextComponent"></div>
    </div>
  </div>
</template>

<script>
import Help from '@/assets/custom/help-transparent.png'
export default {
  name: 'AppHelpCenter',
  data: () => {
    return {
      isHelpSidebarOpen: false,
      helpTextComponent: '',
    }
  },
  setup() {
    return {
      Help,
    }
  },
  mounted() {
    this.loadHelpTextComponent()
    this.addClickOutsideListener()
  },
  beforeUnmount() {
    this.removeClickOutsideListener()
  },
  watch: {
    $route() {
      this.loadHelpTextComponent()
    },
  },
  methods: {
    showHelpSidebar() {
      this.isHelpSidebarOpen = !this.isHelpSidebarOpen
    },
    async loadHelpTextComponent() {
      const helpTextMap = {
        '/master-data/division/list': () =>
          import('!!raw-loader!../assets/help-centre/division/list.html'),
        '/master-data/division/add': () =>
          import('!!raw-loader!../assets/help-centre/division/add.html'),
        '/sales/add': () => import('!!raw-loader!../assets/help-centre/sales/add.html'),
      }
      const currentUrl = window.location.pathname
      if (helpTextMap[currentUrl]) {
        try {
          const importedHelp = await helpTextMap[currentUrl]()
          this.helpTextComponent = importedHelp.default
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const importedHelp = await import('!!raw-loader!../assets/help-centre/not-available.html')
          this.helpTextComponent = importedHelp.default
        } catch (error) {
          console.log(error)
        }
      }
    },
    addClickOutsideListener() {
      document.body.addEventListener('click', this.handleClickOutside)
    },
    removeClickOutsideListener() {
      document.body.removeEventListener('click', this.handleClickOutside)
    },
    handleClickOutside(event) {
      const helpButton = document.querySelector('.help-button')
      if (
        this.isHelpSidebarOpen &&
        this.$refs.helpPanel &&
        !this.$refs.helpPanel.contains(event.target) &&
        !helpButton.contains(event.target)
      ) {
        this.isHelpSidebarOpen = false
      }
    },
  },
}
</script>
