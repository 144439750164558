// /* eslint-disable */
import axiosInstance from '@/libs/axios-instance'
import { createStore } from 'vuex'
import { setCookie } from '@/libs/utils'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    authUser: {},
    permissions: [],
    toasts: [],
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setAuthUser(state, user) {
      var obj = { ...user }
      state.permissions = obj.permissions
      delete obj['permissions']
      state.authUser = obj
    },
    setLogout(state) {
      state.permissions = null
      state.authUser = null
    },
    setToast(state, toast) {
      state.toasts.push(toast)
    },
    clearToast(state) {
      state.toasts = []
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const authData = await axiosInstance.post('auth/login', {
          username: credentials.username,
          password: credentials.password,
        })
        if (authData.data.token.accessToken) {
          setCookie('accessToken', authData.data.token.accessToken)
          commit('setAuthUser', authData.data.user)
          return authData.data
        }
      } catch (error) {
        return error
      }
    },
    async getUser({ commit }) {
      let usr = {}
      await axiosInstance
        .get('me')
        .then((res) => {
          commit('setAuthUser', res.data.data)
          usr = res.data.data
        })
        .catch((e) => e.response)
      return usr
    },
    async prepareLogout({ commit }, server = true) {
      if (server) {
        await axiosInstance.post('logout').catch(({ response }) => {
          if (response.status == 401) {
            Cookies.remove('user')
            Cookies.remove('permissions')
            Cookies.remove('accessToken')
            commit('setLogout')
          }
        })
      }
      Cookies.remove('user')
      Cookies.remove('permissions')
      Cookies.remove('accessToken')
      commit('setLogout')
    },
    setToast({ commit }, toast) {
      commit('setToast', toast)
    },
    clearToast({ commit }) {
      commit('clearToast')
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'user',
      storage: {
        getItem: (key) => (Cookies.get(key) ? JSON.parse(Cookies.get(key)) : null),
        setItem: (key, value) => Cookies.set(key, JSON.stringify(value)),
        removeItem: (key) => Cookies.remove(key),
      },
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => JSON.parse(value),
      reducer: (state) => ({
        sidebarUnfoldable: state.sidebarUnfoldable,
        authUser: state.authUser,
      }),
    }),
    createPersistedState({
      key: 'permissions',
      storage: {
        getItem: (key) => (Cookies.get(key) ? JSON.parse(Cookies.get(key)) : null),
        setItem: (key, value) => Cookies.set(key, JSON.stringify(value)),
        removeItem: (key) => Cookies.remove(key),
      },
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => JSON.parse(value),
      reducer: (state) => ({
        permissions: state.permissions,
      }),
    }),
  ],
})
