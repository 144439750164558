<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader :user="authUser" />
      <div class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
      <AppHelpCenter />
      <Toast />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppHelpCenter from '@/components/AppHelpCenter.vue'
import Toast from '@/components/Toast.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    AppHelpCenter,
    Toast,
  },
  computed: {
    ...mapState(['authUser']),
  },
  created() {
    this.checkDataAuthorizedUser()
  },
  methods: {
    ...mapActions(['getUser']),
    async checkDataAuthorizedUser() {
      if (!this.authUser || !this.authUser.id) {
        // tetep harus kasih ini kalau session cookies expired
        await this.getUser()
      }
    },
  },
}
</script>
